@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
    font-family: "Inter", sans-serif;
    scroll-behavior: smooth;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 16px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #5e6364;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.react-multi-carousel-item {
    transform-origin: center;
    transform: scale(0.75) !important;
    transition: ease-in-out 0.75s;
}
.react-multi-carousel-item--active {
    transform-origin: center;
    transform: scale(1.25) translateY(-0%) !important;
    padding: 32px;
    height: 110% !important;
    transition: ease-in-out 0.75s;
}

@media (max-width: 800px) {
    .react-multi-carousel-item--active {
        transform: scale(1.025) translateY(-0%) !important;
        padding: 0;
    }
}
